import React from 'react';

const IconoBasurero = ({
  width = 40,
  height = 40,
  fillColor = 'black',
  onClick,
}) => {
  return (
    <svg
      fill={fillColor}
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}>
      <path d="M8 26c0 1.656 1.343 3 3 3h10c1.656 0 3-1.344 3-3l2-16h-20l2 16zM19 13h2v13h-2v-13zM15 13h2v13h-2v-13zM11 13h2v13h-2v-13zM25.5 6h-6.5c0 0-0.448-2-1-2h-4c-0.553 0-1 2-1 2h-6.5c-0.829 0-1.5 0.671-1.5 1.5s0 1.5 0 1.5h22c0 0 0-0.671 0-1.5s-0.672-1.5-1.5-1.5z"></path>
    </svg>
  );
};

export default IconoBasurero;
