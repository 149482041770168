import React, {useState} from 'react';
import './input.css';
import {useFormContext} from 'react-hook-form';
import {encontrarErrorInput, formaInvalida} from '../../utils';

const Input = ({
  label,
  type,
  id,
  name,
  placeholder,
  icon,
  width,
  readOnly = false,
  validationOptions,
}) => {
  const {
    register,
    watch,
    formState: {errors},
  } = useFormContext();

  const errores = encontrarErrorInput(errors, name);
  const esInvalido = formaInvalida(errores);

  const watchValue = watch(name, '');

  return (
    <div className="inputContainer" style={{width: width}}>
      {label && (
        <div>
          <label htmlFor={id}>{label}</label>
        </div>
      )}
      {!label && placeholder && watchValue !== '' && (
        <div>
          <label htmlFor={id}>{placeholder}</label>
        </div>
      )}
      {esInvalido && <InputError mensaje={errores.error.message} />}
      <div className="inputFieldContainer">
        {icon}
        <input
          className="inputField"
          type={type}
          id={id}
          placeholder={placeholder}
          name={name}
          {...register(name, validationOptions)}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

const InputError = ({mensaje}) => {
  return <div style={{color: 'red', fontWeight: 'bold'}}>{mensaje}</div>;
};

export default Input;
