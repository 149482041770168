import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {useParams} from 'react-router';
import {getDoc, doc, setDoc} from '@firebase/firestore';
import {useNavigate} from 'react-router';
import {useDispatch} from 'react-redux';
import {mostrar_mensaje} from '../redux/messageSlice';
import Input from '../components/Input/input';
import {useForm, FormProvider} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {firestore} from '../firebaseconfig';

const EditPermiso = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {permiso_editar} = useSelector(state => state.edicion);
  const metodos = useForm();
  const [errorEstado, setErrorEstado] = useState(false);

  const onSubmit = metodos.handleSubmit(async datos => {
    try {
      if (datos.estado === '') {
        setErrorEstado(true);
        return;
      }
      const permRef = doc(firestore, 'permisos', permiso_editar.uid);
      await setDoc(permRef, {estado: datos.estado}, {merge: true});
      dispatch(mostrar_mensaje('Permiso actualizado con exito!'));
      navigate('/permisos');
    } catch (error) {
      console.error(error);
      dispatch(mostrar_mensaje(JSON.stringify(error)));
    }
  });

  useEffect(() => {
    metodos.reset(permiso_editar);
  }, [permiso_editar]);

  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <h1 className="red">Editar Permiso</h1>
        <FormProvider {...metodos}>
          <form
            onSubmit={e => e.preventDefault()}
            noValidate
            className="flex_col_container center"
            style={{width: '300px'}}>
            <Input
              type={'text'}
              id={'nombre'}
              name={'nombre'}
              placeholder={'Nombre Permiso'}
              readOnly={true}
            />

            {errorEstado && (
              <div style={{color: 'red', fontWeight: 'bold'}}>
                Debe seleccionar un estado
              </div>
            )}
            <select
              {...metodos.register('estado')}
              className="round"
              onChange={() => setErrorEstado(false)}>
              <option value="">Seleccione ...</option>
              <option value="A">Activo</option>
              <option value="I">Inactivo</option>
            </select>

            <div onClick={onSubmit} className="boton_rojo" role="button">
              Actualizar Permiso
            </div>
          </form>
        </FormProvider>
      </Container>
    </div>
  );
};

export default EditPermiso;
