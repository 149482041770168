import React, {useContext, useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import IconoLapiz from '../components/iconos/icono_lapiz';
import IconoMas from '../components/iconos/icono_mas';
import {AuthContext} from '../AuthProvider';
import IconoCandado from '../components/iconos/iconoCandado';

const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const navigate = useNavigate();
  const {getToken, user} = useContext(AuthContext);

  const getUsuarios = async () => {
    if (!user) return;
    const url = 'https://getusuarios-i52rdlruva-uc.a.run.app';
    const token = await getToken();
    console.log(token);
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      const data = res.data;
      setUsuarios(data);
    }
  };

  useEffect(() => {
    getUsuarios();
    // eslint-disable-next-line
  }, [user]);

  const handleNewUser = () => {
    navigate('/usuarios/new');
  };

  const handleEditUser = id => {
    navigate(`/usuarios/edit/${id}`);
  };

  const handleCambiarPassword = id => {
    navigate(`/usuarios/changepass/${id}`);
  };
  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <h1 className="red">Usuarios</h1>
        <div
          style={{alignSelf: 'flex-start'}}
          className="boton_rojo"
          role="button"
          onClick={handleNewUser}>
          <IconoMas fillColor="white" /> Nuevo Usuario
        </div>
        <table className="redTable">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Correo Electrónico</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {usuarios.map((user, indice) => {
              return (
                <tr key={`usuario-${indice}`}>
                  <td>{user.displayName}</td>
                  <td>{user.email}</td>
                  <td>{user.estado === 'A' ? 'Activo' : 'Inactivo'}</td>
                  <td>
                    <span
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Editar Usuario"
                      data-tooltip-place="top">
                      <IconoLapiz
                        fillColor="#8e33ff"
                        onClick={() => handleEditUser(user.uid)}
                      />
                    </span>
                    <span
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Cambiar contraseña"
                      data-tooltip-place="top">
                      <IconoCandado
                        width={'40px'}
                        height={'40px'}
                        onClick={() => handleCambiarPassword(user.uid)}
                      />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Container>
    </div>
  );
};

export default Usuarios;
