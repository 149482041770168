import React, {useEffect, useState} from 'react';
import {Container, Form} from 'react-bootstrap';
import {firestore} from '../firebaseconfig';
import {collection, getDocs, addDoc, query, where} from 'firebase/firestore';
import {useNavigate} from 'react-router-dom';
import Input from '../components/Input/input';
import {useForm, FormProvider} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {mostrar_mensaje} from '../redux/messageSlice';

const NewRol = () => {
  const metodos = useForm();

  const [permisos, setPermisos] = useState([]);
  const [permisosRol, setPermisosRol] = useState([]);
  const [errorPermisos, setErrorPermisos] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cargarPermisos = async () => {
    try {
      const permRef = collection(firestore, 'permisos');
      const q = query(permRef, where('estado', '==', 'A'));
      const permisos = await getDocs(q);
      const datosPermisos = permisos.docs.map(doc => {
        return {uid: doc.id, ...doc.data()};
      });
      setPermisos(datosPermisos);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    cargarPermisos();
  }, []);

  const cambioCheck = (e, uid) => {
    if (e.target.checked && !permisosRol.find(p => p === uid)) {
      const nuevosPermisos = [...permisosRol, uid];
      setPermisosRol(nuevosPermisos);
    } else if (!e.target.checked) {
      const nuevosPermisos = permisosRol.filter(p => p !== uid);
      setPermisosRol(nuevosPermisos);
    }
  };

  const onSubmit = metodos.handleSubmit(async datos => {
    if (permisosRol.length === 0) {
      setErrorPermisos(true);
      return;
    }
    const data = {
      nombre: datos.nombre_rol,
      estado: 'A',
      permisos: permisosRol,
    };

    try {
      const docRef = await addDoc(collection(firestore, 'roles'), data);
      console.log('Rol creado con ID:', docRef.id);
      dispatch(mostrar_mensaje('Rol creado con éxito!'));
      navigate('/roles');
    } catch (error) {
      console.error(error);
    }
  });

  useEffect(() => {
    console.log(permisosRol);
  }, [permisosRol]);

  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <h1 className="red">Nuevo Rol</h1>
        <FormProvider {...metodos}>
          <form
            onSubmit={e => e.preventDefault()}
            noValidate
            className="flex_col_container center"
            style={{width: '300px'}}>
            <Input
              type={'text'}
              id={'nombre_rol'}
              name={'nombre_rol'}
              placeholder={'Nombre de Rol'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
            />

            <h2>Permisos</h2>
            {errorPermisos && (
              <div style={{color: 'red', fontWeight: 'bold'}}>
                Debe seleccionar por lo menos un permiso
              </div>
            )}
            <div className="rounded-box" style={{height: '300px'}}>
              <div className="list">
                {permisos.map((perm, indice) => {
                  let className = 'list-option';
                  if (indice === 0) {
                    className = 'list-option-first';
                  } else if (indice === permisos.length - 1) {
                    className = 'list-option-last';
                  } else {
                    className = 'list-option';
                  }
                  return (
                    <div key={`perm-${indice}`} className={className}>
                      <Form.Check
                        type={'checkbox'}
                        label={perm.nombre}
                        id={perm.uid}
                        onChange={e => cambioCheck(e, perm.uid)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div onClick={onSubmit} className="boton_rojo" role="button">
              Crear Rol
            </div>
          </form>
        </FormProvider>
      </Container>
    </div>
  );
};

export default NewRol;
