import React from 'react';
import {Image} from 'react-bootstrap';
import Icono_Candado from './icono_candado.png';

const IconoCandado = ({width, height, onClick}) => {
  return (
    <Image style={{width, height}} src={Icono_Candado} onClick={onClick} />
  );
};

export default IconoCandado;
