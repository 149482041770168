import React, {useContext} from 'react';
import {Container} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {mostrar_mensaje} from '../redux/messageSlice';
import {useNavigate, useParams} from 'react-router-dom';
import Input from '../components/Input/input';
import axios from 'axios';
import {AuthContext} from '../AuthProvider';
import {useForm, FormProvider} from 'react-hook-form';

const NuevoPass = () => {
  const metodos = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();
  const {getToken, user} = useContext(AuthContext);

  const onSubmit = metodos.handleSubmit(async datos => {
    try {
      const url = 'https://cambiarpassword-i52rdlruva-uc.a.run.app';
      const token = await getToken();

      const payload = {uid: id, password: datos.password};

      const res = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.status === 200) {
        console.log(res);
        dispatch(mostrar_mensaje('Contraseña actualizada con exito!'));
        navigate('/usuarios');
      } else {
        dispatch(
          mostrar_mensaje(`Error al cambiar contraseña ${res.statusText}`),
        );
      }
    } catch (error) {
      if (error.name && error.name === 'AxiosError') {
        if (error.response.data.code === 'auth/phone-number-already-exists') {
          dispatch(
            mostrar_mensaje(
              `Error al crear usuario, número de teléfono ya existe`,
            ),
          );
        }
      } else {
        dispatch(mostrar_mensaje(JSON.stringify(error)));
      }
      console.error(error);
    }
  });

  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <h1 className="red">Cambiar Contraseña</h1>
        <FormProvider {...metodos}>
          <form
            onSubmit={e => e.preventDefault()}
            noValidate
            className="flex_col_container center"
            style={{width: '400px'}}>
            <Input
              type={'password'}
              id={'password'}
              name={'password'}
              placeholder={'Contraseña'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
                pattern: {
                  value:
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
                  message:
                    'Contraseña debe contener: mínimo 8 caracteres, una letra mayuscula, una letra minuscula, un número y puede utilizar caracteres especiales',
                },
              }}
            />

            <Input
              type={'password'}
              id={'password_confirm'}
              name={'password_confirm'}
              placeholder={'Confirmar Contraseña'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
                validate: {
                  iguales: v =>
                    metodos.getValues('password') === v ||
                    'Contraseña y confirmación deben ser iguales',
                },
              }}
            />

            <div
              onClick={onSubmit}
              className="boton_rojo"
              role="button"
              disabled={!user}>
              Cambiar Contraseña
            </div>
          </form>
        </FormProvider>
      </Container>
    </div>
  );
};

export default NuevoPass;
