import {createSlice} from '@reduxjs/toolkit';

const estado_inicial = {
  permiso_editar: null,
  sol_editar: null,
  oferta_editar: null,
  eval_editar: null,
  marca_editar: null,
};

export const editSlice = createSlice({
  name: 'edicion',
  initialState: estado_inicial,
  reducers: {
    setPermiso: (state, action) => {
      state.permiso_editar = action.payload;
    },
    setSolicitud: (state, action) => {
      state.sol_editar = action.payload;
    },
    setOferta: (state, action) => {
      state.oferta_editar = action.payload;
    },
    setEval: (state, action) => {
      state.eval_editar = action.payload;
    },
    setMarca: (state, action) => {
      state.marca_editar = action.payload;
    },
  },
});

export const {setPermiso, setSolicitud, setOferta, setEval, setMarca} =
  editSlice.actions;

export default editSlice.reducer;
