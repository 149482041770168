import React, {useContext, useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {AuthContext} from '../AuthProvider';
import {firestore} from '../firebaseconfig';
import {collection, getDocs} from 'firebase/firestore';
import {useNavigate} from 'react-router-dom';
import IconoLapiz from '../components/iconos/icono_lapiz';
import {useDispatch} from 'react-redux';
import {setSolicitud} from '../redux/editSlice';
import axios from 'axios';

const Solicitudes = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [solicitudes, setSolicitudes] = useState([]);
  const {getToken, user} = useContext(AuthContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getUsuarios = async () => {
    if (!user) return;
    const url = 'https://getusuarios-i52rdlruva-uc.a.run.app';
    const token = await getToken();
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      const data = res.data;
      setUsuarios(data);
    }
  };

  const getSolicitudes = async () => {
    if (!user || !usuarios || usuarios.length === 0) return;
    const solArray = [];
    const querySnapshot = await getDocs(collection(firestore, 'solicitud'));
    console.log(usuarios);
    querySnapshot.forEach(doc => {
      const data = doc.data();
      console.log(data);
      const usuario = usuarios.find(usr => usr.uid === data.id_usuario);
      console.log(usuario);
      solArray.push({
        ...data,
        usuario_nombre: usuario?.displayName,
        uid: doc.id,
      });
    });
    console.log(solArray);
    setSolicitudes(solArray);
  };

  useEffect(() => {
    getUsuarios();
  }, [user]);

  useEffect(() => {
    getSolicitudes();
  }, [usuarios]);

  const handleEditSolicitud = sol => {
    dispatch(
      setSolicitud({
        uid: sol.uid,
        id_marca: sol.id_marca,
        anio: sol.anio,
        modelo: sol.modelo,
        id_usuario: sol.id_usuario,
        descripcion: sol.descripcion,
        pieza: sol.pieza,
        estado: sol.estado,
        usuario_nombre: sol.usuario_nombre,
        fecha_cierre:
          sol.fecha_cierre !== null
            ? sol.fecha_cierre.toDate().toISOString()
            : null,
        fecha_apertura: sol.fecha_apertura.toDate().toISOString(),
      }),
    );
    navigate(`/solicitudes/edit/${sol.uid}`);
  };

  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <h1 className="red">Solicitudes</h1>
        <table className="redTable">
          <thead>
            <tr>
              <th>Usuario</th>
              <th>Descripción</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {solicitudes.map((sol, indice) => {
              return (
                <tr key={`sol-${indice}`}>
                  <td>{sol.usuario_nombre}</td>
                  <td>{sol.descripcion}</td>
                  <td>{sol.estado === 'A' ? 'Activo' : 'Inactivo'}</td>
                  <td>
                    <span
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Editar Solicitud"
                      data-tooltip-place="top">
                      <IconoLapiz
                        fillColor="#8e33ff"
                        onClick={() => handleEditSolicitud(sol)}
                      />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Container>
    </div>
  );
};

export default Solicitudes;
