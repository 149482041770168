import React, {useEffect, useState, useContext} from 'react';
import {Container} from 'react-bootstrap';
import {AuthContext} from '../AuthProvider';
import {firestore} from '../firebaseconfig';
import {collection, getDocs} from 'firebase/firestore';
import {useNavigate} from 'react-router-dom';
import IconoLapiz from '../components/iconos/icono_lapiz';
import {useDispatch} from 'react-redux';
import axios from 'axios';
import {setEval} from '../redux/editSlice';

const Evaluaciones = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [evaluaciones, setEvaluaciones] = useState([]);
  const {getToken, user} = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getUsuarios = async () => {
    if (!user) return;
    const url = 'https://getusuarios-i52rdlruva-uc.a.run.app';
    const token = await getToken();
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      const data = res.data;
      setUsuarios(data);
    }
  };

  useEffect(() => {
    getUsuarios();
  }, [user]);

  const getEvaluaciones = async () => {
    if (!user || !usuarios || usuarios.length === 0) return;
    const evalArray = [];
    const querySnapshot = await getDocs(collection(firestore, 'evaluaciones'));
    querySnapshot.forEach(doc => {
      const data = doc.data();
      const usuario = usuarios.find(usr => usr.uid === data.id_evaluador);
      evalArray.push({
        ...data,
        usuario_nombre: usuario?.displayName,
        uid: doc.id,
      });
    });
    setEvaluaciones(evalArray);
  };

  useEffect(() => {
    getEvaluaciones();
  }, [usuarios]);

  const handleEditEval = evl => {
    dispatch(
      setEval({
        ...evl,
        fecha_hora: evl.fecha_hora.toDate().toISOString(),
      }),
    );
    navigate(`/evaluaciones/edit`);
  };

  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <h1 className="red">Evaluaciones</h1>
        <table className="redTable">
          <thead>
            <tr>
              <th>Usuario</th>
              <th>Evaluación</th>
              <th>Comentario</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {evaluaciones.map((evl, indice) => {
              return (
                <tr key={`evl-${indice}`}>
                  <td>{evl.usuario_nombre}</td>
                  <td>{evl.evaluacion}</td>
                  <td>{evl.comentario}</td>
                  <td>{evl.estado === 'A' ? 'Activo' : 'Inactivo'}</td>
                  <td>
                    <span
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Editar Oferta"
                      data-tooltip-place="top">
                      <IconoLapiz
                        fillColor="#8e33ff"
                        onClick={() => handleEditEval(evl)}
                      />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Container>
    </div>
  );
};

export default Evaluaciones;
