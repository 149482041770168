import React, {useEffect, useState, useContext} from 'react';
import {Container, Form} from 'react-bootstrap';
import {useForm, FormProvider, set} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {mostrar_mensaje} from '../redux/messageSlice';
import {useNavigate, useParams} from 'react-router-dom';
import Input from '../components/Input/input';
import {firestore} from '../firebaseconfig';
import {collection, getDocs, setDoc, doc, getDoc} from 'firebase/firestore';
import {APIProvider, AdvancedMarker, Map} from '@vis.gl/react-google-maps';
import IconoYonker from '../components/iconos/icono_yonker';
import axios from 'axios';
import {AuthContext} from '../AuthProvider';

const EditUser = () => {
  const metodos = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [esYonker, setEsYonker] = useState(false);
  const position = {lat: 14.06522, lng: -87.17122};
  const [roles, setRoles] = useState([]);
  const [errorRol, setErrorRol] = useState(false);
  const {id} = useParams();

  const [yonkerPosition, setYonkerPosition] = useState({
    lat: 14.06522,
    lng: -87.17122,
  });
  const {getToken, user} = useContext(AuthContext);

  const cargarRoles = async () => {
    try {
      const rolesRef = collection(firestore, 'roles');
      const roles = await getDocs(rolesRef);
      const datosRoles = roles.docs.map(doc => {
        return {uid: doc.id, ...doc.data()};
      });
      setRoles([{uid: '', nombre: 'Seleccione un rol'}, ...datosRoles]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    cargarRoles();
  }, []);

  const handleCheckYonker = e => {
    setEsYonker(e.target.checked);
  };

  const handleMapMouseClick = e => {
    setYonkerPosition(e.detail.latLng);
  };

  const onSubmit = metodos.handleSubmit(async datos => {
    try {
      if (datos.id_rol === '') {
        setErrorRol(true);
        return;
      }

      const url = 'https://actualizarusuario-i52rdlruva-uc.a.run.app';
      const token = await getToken();

      const payload = {...datos, uid: id, disabled: datos.estado !== 'A'};

      const res = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (res.status === 200) {
        console.log(res);
        const uid = res.data.uid;

        if (esYonker) {
          await setDoc(doc(firestore, 'usuario_yonker', uid), {
            coordenadas: yonkerPosition,
            estado: datos.estado,
            rtn: datos.rtn,
            telefono: datos.phoneNumber,
          });
        }
        dispatch(mostrar_mensaje('Usuario actualizado con exito!'));
        navigate('/usuarios');
      } else {
        if (
          res.data.code &&
          res.data.code === 'auth/phone-number-already-exists'
        ) {
          dispatch(
            mostrar_mensaje(
              `Error al crear usuario, número de teléfono ya existe`,
            ),
          );
        } else {
          dispatch(mostrar_mensaje(`Error al crear usuario ${res.statusText}`));
        }
      }
    } catch (error) {
      if (error.name && error.name === 'AxiosError') {
        if (error.response.data.code === 'auth/phone-number-already-exists') {
          dispatch(
            mostrar_mensaje(
              `Error al crear usuario, número de teléfono ya existe`,
            ),
          );
        }
      } else {
        dispatch(mostrar_mensaje(JSON.stringify(error)));
      }

      console.error(error);
    }
  });

  const cargarDatos = async () => {
    if (!user) return;
    const url = 'https://getusuario-i52rdlruva-uc.a.run.app';
    const payload = {uid: id};
    const token = await getToken();
    const res = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      //Verificar si es Yonker
      try {
        const resData = res.data;
        const docRef = doc(firestore, `/usuario_yonker/${id}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const datos = docSnap.data();
          setEsYonker(true);
          setYonkerPosition(datos.coordenadas);
          const datosReset = {
            displayName: resData.displayName,
            email: resData.email,
            phoneNumber: resData.phoneNumber,
            id_rol: resData.id_rol,
            rtn: datos.rtn,
            estado: resData.estado,
          };
          metodos.reset(datosReset);
        } else {
          setEsYonker(false);
          const datosReset = {
            displayName: resData.displayName,
            email: resData.email,
            phoneNumber: resData.phoneNumber,
            id_rol: resData.id_rol,
            estado: resData.estado,
          };
          metodos.reset(datosReset);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      dispatch(mostrar_mensaje('Usuario no existe en la base de datos.'));
      navigate('/usuarios');
    }
  };

  useEffect(() => {
    cargarDatos();
  }, [id, user]);

  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <h1 className="red">Editar Usuario</h1>
        <FormProvider {...metodos}>
          <form
            onSubmit={e => e.preventDefault()}
            noValidate
            className="flex_col_container center"
            style={{width: '400px'}}>
            <Input
              type={'text'}
              id={'displayName'}
              name={'displayName'}
              placeholder={'Nombre Completo'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
            />

            <Input
              type={'text'}
              id={'phoneNumber'}
              name={'phoneNumber'}
              placeholder={'Número de teléfono'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
                pattern: {
                  value: /^\+\d{1,3}\d{8}$/g,
                  message:
                    'Número de teléfono invalido (formato: +código pais ####-####)',
                },
              }}
            />

            {errorRol && (
              <div style={{color: 'red', fontWeight: 'bold'}}>
                Debe seleccionar un Rol
              </div>
            )}
            <label htmlFor="id_rol">Rol</label>
            <select
              {...metodos.register('id_rol')}
              id={'id_rol'}
              className="round"
              onChange={() => setErrorRol(false)}>
              {roles.map((rol, indice) => {
                return (
                  <option key={`rol-${indice}`} value={rol.uid}>
                    {rol.nombre}
                  </option>
                );
              })}
            </select>

            <label htmlFor="estado">Estado</label>
            <select
              {...metodos.register('estado')}
              className="round"
              id={'estado'}>
              <option value="">Seleccione Estado</option>
              <option value="A">Activo</option>
              <option value="I">Inactivo</option>
            </select>

            <Form.Check
              type={'checkbox'}
              label={'Es usuario Yonker'}
              id={'chk_yonker'}
              onChange={handleCheckYonker}
              checked={esYonker}
            />

            {esYonker && (
              <div
                style={{
                  alignItems: 'center',
                  width: '400px',
                }}>
                <Input
                  type={'text'}
                  id={'rtn'}
                  name={'rtn'}
                  placeholder={'RTN'}
                  validationOptions={{
                    required: {
                      value: true,
                      message: 'Campo requerido',
                    },
                  }}
                />
                <h2>
                  Seleccione Ubicación: <IconoYonker />
                </h2>
                <APIProvider apiKey={'AIzaSyB9pfp6jda2R_aF7SPxUM-28gJCPtNN5lc'}>
                  <Map
                    defaultCenter={position}
                    defaultZoom={15}
                    style={{width: '400px', height: '400px'}}
                    onClick={handleMapMouseClick}
                    mapId={'654cfb77c096a9d5'}
                    disableDefaultUI={true}
                    clickableIcons={false}>
                    <AdvancedMarker position={yonkerPosition}>
                      <IconoYonker width="40px" height="40px" />
                    </AdvancedMarker>
                  </Map>
                </APIProvider>
                <div style={{width: '400px', textAlign: 'center'}}>
                  {`Ubicación actual:Lat:${yonkerPosition.lat.toFixed(
                    5,
                  )}, Lng:${yonkerPosition.lng.toFixed(5)}`}
                </div>
              </div>
            )}
            <div onClick={onSubmit} className="boton_rojo" role="button">
              Actualizar Usuario
            </div>
          </form>
        </FormProvider>
      </Container>
    </div>
  );
};

export default EditUser;
