import React from 'react';
import {Button, Modal} from 'react-bootstrap';

const ConfirmDialog = ({titulo, contenido, onClickYes, onClickNo, mostrar}) => {
  return (
    <Modal show={mostrar} onHide={onClickNo}>
      <Modal.Header closeButton>
        <Modal.Title>{titulo}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{contenido}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={onClickYes}>
          Si
        </Button>
        <Button variant="danger" onClick={onClickNo}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDialog;
