import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {firestore} from '../firebaseconfig';
import {collection, getDocs, doc, deleteDoc} from 'firebase/firestore';
import IconoLapiz from '../components/iconos/icono_lapiz';
import IconoBasurero from '../components/iconos/icono_basurero';
import IconoMas from '../components/iconos/icono_mas';
import {useNavigate} from 'react-router-dom';
import ConfirmDialog from '../components/confirm/confirm';
import {useDispatch} from 'react-redux';
import {mostrar_mensaje} from '../redux/messageSlice';

const Roles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [roles, setRoles] = useState([]);
  const [mostrarDelete, setMostrarDelete] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const cargarRoles = async () => {
    try {
      const rolesRef = collection(firestore, 'roles');
      const roles = await getDocs(rolesRef);
      const datosRoles = roles.docs.map(doc => {
        return {uid: doc.id, ...doc.data()};
      });
      setRoles(datosRoles);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    cargarRoles();
  }, []);

  const handleNewRol = () => {
    navigate('/roles/new');
  };

  const handleEditRol = id => {
    navigate(`/roles/edit/${id}`);
  };

  const handleDeleteRol = id => {
    setDeleteId(id);
    setMostrarDelete(true);
  };

  const handleDeleteYes = async () => {
    try {
      await deleteDoc(doc(firestore, 'roles', deleteId));
      dispatch(mostrar_mensaje('Rol eliminado con éxito!'));
      setMostrarDelete(false);
      cargarRoles();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteNo = () => {
    setDeleteId('');
    setMostrarDelete(false);
  };
  return (
    <div className="fondo_autos">
      <Container className="contenedor" style={{width: '600px'}}>
        <h1 className="red">Roles</h1>
        <div
          style={{alignSelf: 'flex-start'}}
          className="boton_rojo"
          role="button"
          onClick={handleNewRol}>
          <IconoMas fillColor="white" /> Nuevo Rol
        </div>
        <table className="redTable">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {roles.map((rol, indice) => {
              return (
                <tr key={`rol-${indice}`}>
                  <td>{rol.nombre}</td>
                  <td>{rol.estado === 'A' ? 'Activo' : 'Inactivo'}</td>
                  <td>
                    <span
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Editar Rol"
                      data-tooltip-place="top">
                      <IconoLapiz
                        fillColor="#8e33ff"
                        onClick={() => handleEditRol(rol.uid)}
                      />
                    </span>
                    <span
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Eliminar Rol"
                      data-tooltip-place="top">
                      <IconoBasurero
                        fillColor="red"
                        onClick={() => handleDeleteRol(rol.uid)}
                      />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Container>
      {mostrarDelete && (
        <ConfirmDialog
          titulo={'Eliminar Rol'}
          contenido={'¿Está seguro que desea eliminar el rol seleccionado?'}
          mostrar={mostrarDelete}
          onClickYes={handleDeleteYes}
          onClickNo={handleDeleteNo}
        />
      )}
    </div>
  );
};

export default Roles;
