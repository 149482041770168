import React from "react";
import { Container, Image } from "react-bootstrap";
import Logo from '../imagenes/logo_transparente.png';
import "./compartidas.css"

const Inicio = () => {
    return (
        <div className="fondo_autos">

            <Container className="contenedor">
                <Image className="logo_login" src={Logo} />
                <h1><span className="bienvenido">Bienvenido</span></h1>
            </Container>

        </div>
    );
}

export default Inicio