import React, {useEffect, useState, useContext} from 'react';
import {Container, Form} from 'react-bootstrap';
import {useForm, FormProvider} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {mostrar_mensaje} from '../redux/messageSlice';
import {useNavigate} from 'react-router-dom';
import Input from '../components/Input/input';
import {firestore} from '../firebaseconfig';
import {collection, getDocs, setDoc, doc} from 'firebase/firestore';
import {APIProvider, AdvancedMarker, Map} from '@vis.gl/react-google-maps';
import IconoYonker from '../components/iconos/icono_yonker';
import axios from 'axios';
import {AuthContext} from '../AuthProvider';

const NewUser = () => {
  const metodos = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [esYonker, setEsYonker] = useState(false);
  const position = {lat: 14.06522, lng: -87.17122};
  const [roles, setRoles] = useState([]);
  const [errorRol, setErrorRol] = useState(false);

  const [yonkerPosition, setYonkerPosition] = useState({
    lat: 14.06522,
    lng: -87.17122,
  });
  const {getToken} = useContext(AuthContext);

  const cargarRoles = async () => {
    try {
      const rolesRef = collection(firestore, 'roles');
      const roles = await getDocs(rolesRef);
      const datosRoles = roles.docs.map(doc => {
        return {uid: doc.id, ...doc.data()};
      });
      setRoles([{uid: '', nombre: 'Seleccione un rol'}, ...datosRoles]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    cargarRoles();
  }, []);

  const handleCheckYonker = e => {
    setEsYonker(e.target.checked);
  };

  const handleMapMouseClick = e => {
    setYonkerPosition(e.detail.latLng);
  };

  const onSubmit = metodos.handleSubmit(async datos => {
    try {
      if (datos.id_rol === '') {
        setErrorRol(true);
        return;
      }
      console.log(datos);
      const url = 'https://crearusuario-i52rdlruva-uc.a.run.app';
      const token = await getToken();
      const res = await axios.post(url, datos, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (res.status === 200) {
        const uid = res.data.uid;
        await setDoc(doc(firestore, 'usuario_ext', uid), {
          estado: 'A',
          id_rol: datos.id_rol,
        });

        if (esYonker) {
          await setDoc(doc(firestore, 'usuario_yonker', uid), {
            coordenadas: yonkerPosition,
            estado: 'A',
            rtn: datos.rtn,
            telefono: datos.phoneNumber,
          });
        }
        dispatch(mostrar_mensaje('Usuario creado con exito!'));
        navigate('/usuarios');
      } else {
        if (
          res.data.code &&
          res.data.code === 'auth/phone-number-already-exists'
        ) {
          dispatch(
            mostrar_mensaje(
              `Error al crear usuario, número de teléfono ya existe`,
            ),
          );
        } else {
          dispatch(mostrar_mensaje(`Error al crear usuario ${res.statusText}`));
        }
      }
    } catch (error) {
      if (error.name && error.name === 'AxiosError') {
        if (error.response.data.code === 'auth/phone-number-already-exists') {
          dispatch(
            mostrar_mensaje(
              `Error al crear usuario, número de teléfono ya existe`,
            ),
          );
        }
      } else {
        dispatch(mostrar_mensaje(JSON.stringify(error)));
      }

      console.error(error);
    }
  });

  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <h1 className="red">Nuevo Usuario</h1>
        <FormProvider {...metodos}>
          <form
            onSubmit={e => e.preventDefault()}
            noValidate
            className="flex_col_container center"
            style={{width: '400px'}}>
            <Input
              type={'text'}
              id={'displayName'}
              name={'displayName'}
              placeholder={'Nombre Completo'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
            />

            <Input
              type={'text'}
              id={'email'}
              name={'email'}
              placeholder={'Correo Electrónico'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
                pattern: {
                  value:
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
                  message: 'Correo electrónico invalido',
                },
              }}
            />

            <Input
              type={'text'}
              id={'phoneNumber'}
              name={'phoneNumber'}
              placeholder={'Número de teléfono'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
                pattern: {
                  value: /^\+\d{1,3}\s\d{4}-\d{4}$/g,
                  message:
                    'Número de teléfono invalido (formato: +código pais ####-####)',
                },
              }}
            />

            {errorRol && (
              <div style={{color: 'red', fontWeight: 'bold'}}>
                Debe seleccionar un Rol
              </div>
            )}
            <select
              {...metodos.register('id_rol')}
              className="round"
              onChange={() => setErrorRol(false)}>
              {roles.map((rol, indice) => {
                return (
                  <option key={`rol-${indice}`} value={rol.uid}>
                    {rol.nombre}
                  </option>
                );
              })}
            </select>

            <Input
              type={'password'}
              id={'password'}
              name={'password'}
              placeholder={'Contraseña'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
                pattern: {
                  value:
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
                  message:
                    'Contraseña debe contener: mínimo 8 caracteres, una letra mayuscula, una letra minuscula, un número y puede utilizar caracteres especiales',
                },
              }}
            />

            <Input
              type={'password'}
              id={'password_confirm'}
              name={'password_confirm'}
              placeholder={'Confirmar Contraseña'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
                validate: {
                  iguales: v =>
                    metodos.getValues('password') === v ||
                    'Contraseña y confirmación deben ser iguales',
                },
              }}
            />

            <Form.Check
              type={'checkbox'}
              label={'Es usuario Yonker'}
              id={'chk_yonker'}
              onChange={handleCheckYonker}
              defaultChecked={false}
            />

            {esYonker && (
              <div
                style={{
                  alignItems: 'center',
                  width: '400px',
                }}>
                <Input
                  type={'text'}
                  id={'rtn'}
                  name={'rtn'}
                  placeholder={'RTN'}
                  validationOptions={{
                    required: {
                      value: true,
                      message: 'Campo requerido',
                    },
                  }}
                />
                <h2>
                  Seleccione Ubicación: <IconoYonker />
                </h2>
                <APIProvider apiKey={'AIzaSyB9pfp6jda2R_aF7SPxUM-28gJCPtNN5lc'}>
                  <Map
                    defaultCenter={position}
                    defaultZoom={15}
                    style={{width: '400px', height: '400px'}}
                    onClick={handleMapMouseClick}
                    mapId={'654cfb77c096a9d5'}
                    disableDefaultUI={true}
                    clickableIcons={false}>
                    <AdvancedMarker position={yonkerPosition}>
                      <IconoYonker width="40px" height="40px" />
                    </AdvancedMarker>
                  </Map>
                </APIProvider>
                <div style={{width: '400px', textAlign: 'center'}}>
                  {`Ubicación actual:Lat:${yonkerPosition.lat.toFixed(
                    5,
                  )}, Lng:${yonkerPosition.lng.toFixed(5)}`}
                </div>
              </div>
            )}
            <div onClick={onSubmit} className="boton_rojo" role="button">
              Crear Usuario
            </div>
          </form>
        </FormProvider>
      </Container>
    </div>
  );
};

export default NewUser;
