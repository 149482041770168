import { createSlice } from "@reduxjs/toolkit";

const estado_inicial = {
    user: null,
    loading: true
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: estado_inicial,
    reducers: {
        actualizarUsuario: (state, action) => {
            console.log(action);
            state.user = action.payload;
        },
        actualizarLoading: (state, action) => {
            state.loading = action.payload;
        }
    }
});

export const { actualizarUsuario, actualizarLoading } = authSlice.actions;

export default authSlice.reducer;