import React from 'react';
import {Container} from 'react-bootstrap';
import {firestore} from '../firebaseconfig';
import {collection, addDoc} from 'firebase/firestore';
import {useNavigate} from 'react-router-dom';
import Input from '../components/Input/input';
import {useForm, FormProvider} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {mostrar_mensaje} from '../redux/messageSlice';
import {storage} from '../firebaseconfig';
import {ref, getDownloadURL, uploadBytesResumable} from 'firebase/storage';

const NewMarca = () => {
  const metodos = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = metodos.handleSubmit(async datos => {
    const data = {
      nombre: datos.nombre_marca,
      url_logo: '',
      estado: 'A',
    };

    const file = datos.logo_marca[0];
    if (!file) return;
    const storageRef = ref(storage, 'logos_marcas/' + file.name);
    const task = uploadBytesResumable(storageRef, file);
    task.on(
      'state_changed',
      snapshot => {
        const porcentaje =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(porcentaje);
      },
      error => {
        console.error(error);
      },
      async () => {
        const url = await getDownloadURL(task.snapshot.ref);
        data.url_logo = url;
        try {
          await addDoc(collection(firestore, 'marcas'), data);
          dispatch(mostrar_mensaje('Marca creada con éxito!'));
          navigate('/marcas');
        } catch (error) {
          console.error(error);
        }
      },
    );
  });

  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <h1 className="red">Nueva Marca</h1>
        <FormProvider {...metodos}>
          <form
            onSubmit={e => e.preventDefault()}
            noValidate
            className="flex_col_container center"
            style={{width: '300px'}}>
            <Input
              type={'text'}
              id={'nombre_marca'}
              name={'nombre_marca'}
              placeholder={'Nombre de Marca'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
            />

            <input
              type="file"
              id="logo_marca"
              name="logo_marca"
              {...metodos.register('logo_marca', {
                required: {value: true, message: 'Campo requerido'},
              })}
            />
            {metodos.formState.errors.logo_marca && (
              <div style={{color: 'red', fontWeight: 'bold'}}>
                {metodos.formState.errors.logo_marca.message}
              </div>
            )}

            <div onClick={onSubmit} className="boton_rojo" role="button">
              Crear Marca
            </div>
          </form>
        </FormProvider>
      </Container>
    </div>
  );
};

export default NewMarca;
