import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import {createContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {auth} from './firebaseconfig';
import {actualizarLoading, actualizarUsuario} from './redux/authSlice';
import {useDispatch} from 'react-redux';

export const AuthContext = createContext(null);

const AuthProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const createUser = (email, password) => {
    setLoading(true);
    dispatch(actualizarLoading(true));
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const loginUser = (email, password) => {
    setLoading(true);
    dispatch(actualizarLoading(true));
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logOut = () => {
    setLoading(true);
    dispatch(actualizarLoading(true));
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, currentUser => {
      setUser(currentUser);
      dispatch(actualizarUsuario(currentUser ? currentUser.uid : null));
      setLoading(false);
      dispatch(actualizarLoading(false));
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  const getToken = async () => {
    console.log(user);
    if (user) {
      const token = await user.getIdToken(true);
      console.log(`Token: ${token}`);
      return token;
    } else {
      return null;
    }
  };

  const authValue = {
    createUser,
    user,
    loginUser,
    logOut,
    loading,
    getToken,
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
