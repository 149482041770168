import React, {useEffect, useState} from 'react';
import {Container, Image} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {firestore} from '../firebaseconfig';
import {setDoc, doc, Timestamp} from 'firebase/firestore';
import {useForm, FormProvider} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {mostrar_mensaje} from '../redux/messageSlice';
import Input from '../components/Input/input';
import {setOferta} from '../redux/editSlice';

const EditOferta = () => {
  const {oferta_editar} = useSelector(state => state.edicion);
  const [errorEstado, setErrorEstado] = useState(false);
  const metodos = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (oferta_editar) {
      const [fecha_cierre, hora_cierre] =
        oferta_editar.fecha_cierre !== null
          ? oferta_editar.fecha_cierre.split('T')
          : [null, null];
      const [fecha_oferta, hora_oferta] = oferta_editar.fecha_oferta.split('T');
      metodos.reset({
        ...oferta_editar,
        fecha_oferta: fecha_oferta,
        fecha_cierre: fecha_cierre,
        hora_oferta: new Date(oferta_editar.fecha_oferta).toLocaleTimeString(
          'es-HN',
        ),
        hora_cierre:
          hora_cierre !== null
            ? new Date(oferta_editar.fecha_cierre).toLocaleTimeString('es-HN')
            : null,
      });
    }
  }, [oferta_editar, metodos]);

  const onSubmit = metodos.handleSubmit(async datos => {
    try {
      if (datos.estado === '') {
        setErrorEstado(true);
        return;
      }

      if (oferta_editar.estado !== datos.estado) {
        let fecha_cierre = null;
        if (datos.estado !== 'A') {
          fecha_cierre = Timestamp.fromDate(new Date());
        }
        await setDoc(
          doc(firestore, `/ofertas/${oferta_editar.uid}`),
          {
            descripcion: datos.descripcion,
            estado: datos.estado,
            fecha_cierre: fecha_cierre,
            monto: datos.monto,
          },
          {merge: true},
        );
      } else {
        await setDoc(
          doc(firestore, `/ofertas/${oferta_editar.uid}`),
          {
            descripcion: datos.descripcion,
            estado: datos.estado,
            monto: datos.monto,
          },
          {merge: true},
        );
      }

      dispatch(mostrar_mensaje('Oferta actualizada con exito!'));
      dispatch(setOferta(null));
      navigate('/ofertas');
    } catch (error) {
      console.error(error);
      dispatch(mostrar_mensaje(JSON.stringify(error)));
    }
  });

  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <h1 className="red">Editar Oferta</h1>
        <FormProvider {...metodos}>
          <form
            onSubmit={e => e.preventDefault()}
            noValidate
            className="flex_col_container center"
            style={{width: '400px'}}>
            <Input
              type={'text'}
              id={'usuario_nombre'}
              name={'usuario_nombre'}
              placeholder={'Nombre de usuario'}
              readOnly={true}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
            />
            <Input
              type={'text'}
              id={'descripcion'}
              name={'descripcion'}
              placeholder={'Descripción'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
            />
            <Input
              type={'text'}
              id={'monto'}
              name={'monto'}
              placeholder={'Monto'}
              validationOptions={{
                valueAsNumber: true,
                validate: {
                  positivo: v =>
                    parseFloat(v) > 0.0 || 'Monto debe ser mayor a cero.',
                },
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
            />
            {errorEstado && (
              <div style={{color: 'red', fontWeight: 'bold'}}>
                Debe seleccionar un estado
              </div>
            )}
            <label htmlFor="estado">Estado</label>
            <select
              {...metodos.register('estado')}
              className="round"
              id={'estado'}
              onChange={() => setErrorEstado(false)}>
              <option value="">Seleccione Estado</option>
              <option value="A">Activo</option>
              <option value="I">Inactivo</option>
            </select>

            <Input
              type={'date'}
              id={'fecha_oferta'}
              name={'fecha_oferta'}
              placeholder={'Fecha de Oferta'}
              readOnly={true}
              validationOptions={{
                valueAsDate: true,
              }}
            />

            <Input
              type={'time'}
              id={'hora_oferta'}
              name={'hora_oferta'}
              placeholder={'Hora de Oferta'}
              readOnly={true}
              validationOptions={{
                valueAsDate: true,
              }}
            />

            <Input
              type={'date'}
              id={'fecha_cierre'}
              name={'fecha_cierre'}
              placeholder={'Fecha de Cierre'}
              readOnly={true}
              validationOptions={{
                valueAsDate: true,
              }}
            />

            <Input
              type={'time'}
              id={'hora_cierre'}
              name={'hora_cierre'}
              placeholder={'Hora de Cierre'}
              readOnly={true}
              validationOptions={{
                valueAsDate: true,
              }}
            />

            <div onClick={onSubmit} className="boton_rojo" role="button">
              Actualizar Oferta
            </div>
          </form>
        </FormProvider>
      </Container>
    </div>
  );
};

export default EditOferta;
