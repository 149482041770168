import {createSlice} from '@reduxjs/toolkit';

const estado_inicial = {
  mostrar: false,
  mensaje: '',
};

export const messageSlice = createSlice({
  name: 'message',
  initialState: estado_inicial,
  reducers: {
    mostrar_mensaje: (state, action) => {
      state.mensaje = action.payload;
      state.mostrar = true;
    },
    cerrar_mensaje: state => {
      state.mensaje = '';
      state.mostrar = false;
    },
  },
});

export const {mostrar_mensaje, cerrar_mensaje} = messageSlice.actions;

export default messageSlice.reducer;
