import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyCh5hLLQ-FjekTtQUgxd9r4HKCjC8BGjF0',
  authDomain: 'autopartxchange.firebaseapp.com',
  projectId: 'autopartxchange',
  storageBucket: 'autopartxchange.appspot.com',
  messagingSenderId: '988595507778',
  appId: '1:988595507778:web:66f143c2f4441deb6313db',
  measurementId: 'G-VGB0CFF89V',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app, 'gs://autopartxchange.appspot.com');
