import React, {useContext} from 'react';
import './header.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from './logo_transparente.png';
import {Image, Toast, ToastContainer} from 'react-bootstrap';
import IconoPerfil from './perfil.svg';
import PanelEncabezado from './panel_encabezado.png';
import {AuthContext} from '../../AuthProvider';
import {Tooltip} from 'react-tooltip';
import {useSelector, useDispatch} from 'react-redux';
import {cerrar_mensaje} from '../../redux/messageSlice';

const Header = ({navigation}) => {
  const {user, logOut, loading} = useContext(AuthContext);

  const {mostrar, mensaje} = useSelector(state => state.message);
  const dispatch = useDispatch();

  const handleClickBrand = () => {
    if (user) navigation.navigate('/inicio');
    else navigation.navigate('/');
  };

  const handleSignOut = () => {
    logOut()
      .then(() => {
        console.log('User logged out successfully');
        navigation.navigate('/'); // Redirect to the login page after logout
      })
      .catch(error => console.error(error));
  };

  const handleClickRoles = () => {
    navigation.navigate('/roles');
  };

  const handleClickPermisos = () => {
    navigation.navigate('/permisos');
  };

  return (
    <Navbar expand="lg" className="header">
      <Container>
        <Navbar.Brand onClick={handleClickBrand}>
          <Image className={'logo'} src={Logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {!user && !loading && (
          <Nav>
            <Nav.Link onClick={() => navigation.navigate('/politica_priv')}>
              Politica de Privacidad
            </Nav.Link>
            <Nav.Link onClick={() => navigation.navigate('/account_delete')}>
              Eliminar Cuenta
            </Nav.Link>
          </Nav>
        )}
        {user && !loading && (
          <Navbar.Collapse
            id="basic-navbar-nav"
            className={'justify-content-end'}>
            {/*<Nav>
              <NavDropdown
                title={'Mensajería Instántanea'}
                id="mensajes-dropdown">
                <NavDropdown.Item>Notificaciones a usuarios</NavDropdown.Item>
                <NavDropdown.Item>Auditoría de mensajes</NavDropdown.Item>
              </NavDropdown>
        </Nav>*/}
            <Nav>
              <Nav.Link onClick={() => navigation.navigate('/marcas')}>
                Marcas
              </Nav.Link>
              <Nav.Link onClick={() => navigation.navigate('/evaluaciones')}>
                Evaluaciones
              </Nav.Link>
              <Nav.Link onClick={() => navigation.navigate('/ofertas')}>
                Ofertas
              </Nav.Link>
              <Nav.Link onClick={() => navigation.navigate('/solicitudes')}>
                Solicitudes
              </Nav.Link>
            </Nav>
            <Nav>
              <NavDropdown title={'Seguridad'} id="basic-nav-dropdown">
                <NavDropdown.Item onClick={handleClickPermisos}>
                  Permisos
                </NavDropdown.Item>
                <NavDropdown.Item onClick={handleClickRoles}>
                  Roles
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigation.navigate('/usuarios')}>
                  Usuarios
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>

            <Nav>
              <NavDropdown
                title={<Image src={IconoPerfil} className="iconoPerfil" />}
                id="perfil-dropdown">
                {/*<NavDropdown.Item>Cambiar Contraseña</NavDropdown.Item>*/}
                <NavDropdown.Item onClick={handleSignOut}>
                  Cerrar Sesión
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        )}
        <Nav>
          <Image src={PanelEncabezado} className="panelEncabezado" />
        </Nav>
      </Container>
      <Tooltip id="my-tooltip" />
      <ToastContainer className="p-3" position={'top-end'} style={{zIndex: 1}}>
        <Toast
          onClose={() => dispatch(cerrar_mensaje())}
          show={mostrar}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong>Mensaje</strong>
          </Toast.Header>
          <Toast.Body>{mensaje}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Navbar>
  );
};

export default Header;
