import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {firestore} from '../firebaseconfig';
import {collection, getDocs} from 'firebase/firestore';
import {useNavigate} from 'react-router-dom';
import IconoLapiz from '../components/iconos/icono_lapiz';
import {useDispatch} from 'react-redux';
import IconoMas from '../components/iconos/icono_mas';
import {setMarca} from '../redux/editSlice';

const Marcas = () => {
  const [marcas, setMarcas] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getMarcas = async () => {
    const tmp = [];
    const querySnapshot = await getDocs(collection(firestore, 'marcas'));
    querySnapshot.forEach(doc => {
      const data = doc.data();
      tmp.push({
        ...data,
        uid: doc.id,
      });
    });
    setMarcas(tmp);
  };

  useEffect(() => {
    getMarcas();
  }, []);

  const handleEditMarca = marca => {
    dispatch(setMarca(marca));
    navigate('/marcas/edit');
  };

  const handleNewMarca = () => {
    navigate('/marcas/new');
  };
  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <h1 className="red">Marcas</h1>
        <div
          style={{alignSelf: 'flex-start'}}
          className="boton_rojo"
          role="button"
          onClick={handleNewMarca}>
          <IconoMas fillColor="white" /> Nueva Marca
        </div>
        <table className="redTable">
          <thead>
            <tr>
              <th>Marca</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {marcas.map((marca, indice) => {
              return (
                <tr key={`marca-${indice}`}>
                  <td>{marca.nombre}</td>
                  <td>{marca.estado === 'A' ? 'Activo' : 'Inactivo'}</td>
                  <td>
                    <span
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Editar Marca"
                      data-tooltip-place="top">
                      <IconoLapiz
                        fillColor="#8e33ff"
                        onClick={() => handleEditMarca(marca)}
                      />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Container>
    </div>
  );
};

export default Marcas;
