export const encontrarErrorInput = (errores, nombre) => {
    const filtrado = Object.keys(errores)
        .filter(key => key.includes(nombre))
        .reduce((cur, key) => {
            return Object.assign(cur, { error: errores[key] })
        }, {});

    return filtrado;
}

export const formaInvalida = (err) => {
    if (Object.keys(err).length > 0) return true;
    else return false;
}