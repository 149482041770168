import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {firestore} from '../firebaseconfig';
import {setDoc, doc} from 'firebase/firestore';
import {useForm, FormProvider} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {mostrar_mensaje} from '../redux/messageSlice';
import Input from '../components/Input/input';
import {setEval} from '../redux/editSlice';

const EditEvaluacion = () => {
  const {eval_editar} = useSelector(state => state.edicion);
  const [errorEstado, setErrorEstado] = useState(false);
  const metodos = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (eval_editar) {
      const fecha_hora = eval_editar.fecha_hora.split('T')[0];
      metodos.reset({
        ...eval_editar,
        fecha_hora: fecha_hora,
        hora_evaluacion: new Date(eval_editar.fecha_hora).toLocaleTimeString(
          'es-HN',
        ),
      });
    }
  }, [eval_editar, metodos]);

  const onSubmit = metodos.handleSubmit(async datos => {
    try {
      if (datos.estado === '') {
        setErrorEstado(true);
        return;
      }

      await setDoc(
        doc(firestore, `/evaluaciones/${eval_editar.uid}`),
        {
          estado: datos.estado,
          comentario: datos.comentario,
          evaluacion: datos.evaluacion,
        },
        {merge: true},
      );

      dispatch(mostrar_mensaje('Evaluación actualizada con exito!'));
      dispatch(setEval(null));
      navigate('/evaluaciones');
    } catch (error) {
      console.error(error);
      dispatch(mostrar_mensaje(JSON.stringify(error)));
    }
  });

  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <h1 className="red">Editar Evaluación</h1>
        <FormProvider {...metodos}>
          <form
            onSubmit={e => e.preventDefault()}
            noValidate
            className="flex_col_container center"
            style={{width: '400px'}}>
            <Input
              type={'text'}
              id={'usuario_nombre'}
              name={'usuario_nombre'}
              placeholder={'Nombre de usuario'}
              readOnly={true}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
            />
            <label htmlFor="evaluacion">Evaluación:</label>
            <select
              {...metodos.register('evaluacion')}
              className="round"
              id={'evaluacion'}>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
            <Input
              type={'text'}
              id={'comentario'}
              name={'comentario'}
              placeholder={'Comentario:'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
            />
            {errorEstado && (
              <div style={{color: 'red', fontWeight: 'bold'}}>
                Debe seleccionar un estado
              </div>
            )}
            <label htmlFor="estado">Estado</label>
            <select
              {...metodos.register('estado')}
              className="round"
              id={'estado'}
              onChange={() => setErrorEstado(false)}>
              <option value="">Seleccione Estado</option>
              <option value="A">Activo</option>
              <option value="I">Inactivo</option>
            </select>
            <Input
              type={'date'}
              id={'fecha_hora'}
              name={'fecha_hora'}
              placeholder={'Fecha de Evaluación'}
              readOnly={true}
              validationOptions={{
                valueAsDate: true,
              }}
            />

            <Input
              type={'time'}
              id={'hora_evaluacion'}
              name={'hora_evaluacion'}
              placeholder={'Hora de Evaluación'}
              readOnly={true}
              validationOptions={{
                valueAsDate: true,
              }}
            />

            <div onClick={onSubmit} className="boton_rojo" role="button">
              Actualizar Evaluación
            </div>
          </form>
        </FormProvider>
      </Container>
    </div>
  );
};

export default EditEvaluacion;
