import React from 'react';
import {Container, Image} from 'react-bootstrap';
import './compartidas.css';
import Logo from '../imagenes/logo_transparente.png';
const DeleteAccount = () => {
  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <Image className="logo_login" src={Logo} />
        <h1>Solicitud de Eliminación de Cuenta</h1>
        <p>
          Si deseas eliminar tu cuenta de AutopartXChange, por favor envía un
          correo electrónico a {''}
          <a href="mailto:contacto@autopartxchange.com">
            contacto@autopartxchange.com
          </a>
          . Una vez que recibamos tu solicitud, procesaremos tu solicitud de
          eliminación de cuenta en un plazo razonable.
        </p>

        <p>
          Por favor ten en cuenta lo siguiente antes de proceder con la
          eliminación de tu cuenta:
        </p>
        <ul>
          <li>
            Al eliminar tu cuenta, perderás acceso a todos los datos y
            configuraciones asociadas con tu cuenta, incluyendo el historial de
            compras y cualquier información personal que hayas proporcionado.
          </li>
          <li>
            No podrás recuperar tu cuenta una vez que haya sido eliminada. Todos
            los datos asociados con tu cuenta serán eliminados permanentemente
            de nuestro sistema.
          </li>
        </ul>

        <h2>Formulario de Solicitud de Eliminación de Cuenta</h2>
        <p>El correo electrónico enviado debe incluir lo siguiente: </p>
        <ul>
          <li>Nombre completo</li>
          <li>Dirección de correo electrónico asociada con la cuenta</li>
          <li>Motivo de la eliminación de la cuenta (opcional)</li>
        </ul>
        <p>
          Si tienes alguna pregunta o necesitas ayuda, por favor contáctanos a
          través de{' '}
          <a href="mailto:contacto@autopartxchange.com">
            contacto@autopartxchange.com
          </a>
          .
        </p>

        <p>Gracias por utilizar AutopartXChange.</p>
      </Container>
    </div>
  );
};

export default DeleteAccount;
