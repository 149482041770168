import React from 'react';
import {Container, Image} from 'react-bootstrap';
import './compartidas.css';
import Logo from '../imagenes/logo_transparente.png';
const PoliticaPriv = () => {
  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <Image className="logo_login" src={Logo} />
        <h1>Política de Privacidad de AutopartXChange</h1>
        <p>
          <strong>Última actualización:</strong> 08/05/2024
        </p>
        <p>
          La presente Política de Privacidad describe cómo AutopartXChange
          ("nosotros", "nos" o "nuestro") recopila, utiliza y comparte la
          información personal de los usuarios ("usuarios" o "usted") de nuestra
          aplicación móvil AutopartXChange ("la aplicación").
        </p>

        <h2>1. Información que Recopilamos</h2>
        <p>
          <strong>Información Personal:</strong>
          <br />
          Podemos recopilar información personal que usted nos proporciona
          directamente cuando utiliza nuestra aplicación, como su nombre,
          dirección de correo electrónico, número de teléfono, dirección postal
          y otra información que usted elija proporcionar.
        </p>

        <p>
          <strong>Información de Registro:</strong>
          <br />
          Recopilamos información sobre su uso de la aplicación, incluidos los
          registros del servidor, la actividad del usuario (como las consultas
          de búsqueda y las compras realizadas) y otros datos de diagnóstico y
          rendimiento.
        </p>

        <p>
          <strong>Información de Dispositivo:</strong>
          <br />
          Podemos recopilar información sobre su dispositivo móvil, incluido el
          modelo de dispositivo, el sistema operativo, los identificadores
          únicos del dispositivo y otros datos relacionados con su interacción
          con nuestra aplicación.
        </p>

        <p>
          <strong>Información de Ubicación:</strong>
          <br />
          Podemos recopilar información sobre su ubicación exacta cuando usted
          nos otorgue permiso para hacerlo. Esta información se utiliza para
          proporcionar funcionalidades basadas en la ubicación de la aplicación
          y mejorar su experiencia de usuario.
        </p>

        <h2>2. Uso de la Información</h2>
        <p>Utilizamos la información recopilada para los siguientes fines:</p>
        <ul>
          <li>Proporcionar y mantener nuestra aplicación.</li>
          <li>Personalizar y mejorar la experiencia del usuario.</li>
          <li>
            Enviarle comunicaciones relacionadas con su uso de la aplicación,
            incluidas actualizaciones, alertas y mensajes administrativos.
          </li>
          <li>
            Realizar análisis y evaluaciones para comprender mejor cómo se
            utiliza nuestra aplicación y mejorar nuestros servicios.
          </li>
        </ul>

        <h2>3. Compartir de la Información</h2>
        <p>
          No vendemos, alquilamos ni compartimos información personal de los
          usuarios con terceros, excepto en las siguientes circunstancias:
        </p>
        <ul>
          <li>Con su consentimiento explícito.</li>
          <li>
            Con proveedores de servicios de confianza que trabajan en nuestro
            nombre y están sujetos a obligaciones de confidencialidad.
          </li>
          <li>
            Cuando sea requerido por ley o en respuesta a procesos legales
            válidos.
          </li>
        </ul>

        <h2>4. Seguridad de la Información</h2>
        <p>
          Tomamos medidas razonables para proteger la información personal de
          los usuarios contra accesos no autorizados, divulgación o destrucción.
          Sin embargo, ninguna transmisión de datos por Internet o
          almacenamiento electrónico es completamente segura, por lo que no
          podemos garantizar su seguridad absoluta.
        </p>

        <h2>5. Cambios en esta Política de Privacidad</h2>
        <p>
          Nos reservamos el derecho de actualizar esta Política de Privacidad en
          cualquier momento. Le notificaremos cualquier cambio mediante la
          publicación de la nueva Política de Privacidad en esta página.
        </p>

        <h2>6. Contacto</h2>
        <p>
          Si tiene alguna pregunta o inquietud sobre nuestra Política de
          Privacidad, por favor contáctenos a través de{' '}
          <a href="mailto:contacto@autopartxchange.com">
            contacto@autopartxchange
          </a>
          .
        </p>
      </Container>
    </div>
  );
};

export default PoliticaPriv;
