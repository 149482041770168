import React, {useEffect, useState} from 'react';
import {Container, Form} from 'react-bootstrap';
import {useParams, useNavigate} from 'react-router-dom';
import {firestore} from '../firebaseconfig';
import {
  doc,
  getDoc,
  collection,
  getDocs,
  setDoc,
  query,
  where,
} from 'firebase/firestore';
import {useDispatch} from 'react-redux';
import {mostrar_mensaje} from '../redux/messageSlice';
import Input from '../components/Input/input';
import {useForm, FormProvider} from 'react-hook-form';

const EditRol = () => {
  const metodos = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [permisos, setPermisos] = useState([]);
  const [permisosRol, setPermisosRol] = useState([]);
  const [errorPermisos, setErrorPermisos] = useState(false);
  const {id} = useParams();

  const cargarPermisos = async () => {
    try {
      const permRef = collection(firestore, 'permisos');
      const q = query(permRef, where('estado', '==', 'A'));
      const permisos = await getDocs(q);
      const datosPermisos = permisos.docs.map(doc => {
        return {uid: doc.id, ...doc.data()};
      });
      setPermisos(datosPermisos);
    } catch (error) {
      console.error(error);
    }
  };

  const cargarDatos = async () => {
    try {
      const docRef = doc(firestore, 'roles', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log(docSnap.data());
        setPermisosRol(docSnap.data().permisos);
        setPermisos([...permisos]);
        metodos.reset({...docSnap.data(), nombre_rol: docSnap.data().nombre});
      } else {
        dispatch(mostrar_mensaje('No existe ese rol'));
        navigate('/roles');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    cargarDatos();
    // eslint-disable-next-line
  }, [id]);

  const onSubmit = metodos.handleSubmit(async datos => {
    if (permisosRol.length === 0) {
      setErrorPermisos(true);
      return;
    }
    const data = {
      nombre: datos.nombre_rol,
      estado: datos.estado,
      permisos: permisosRol,
    };

    try {
      await setDoc(doc(firestore, 'roles', id), data);
      dispatch(mostrar_mensaje('Rol actualizado con éxito!'));
      navigate('/roles');
    } catch (error) {
      console.error(error);
    }
  });

  const cambioCheck = (e, uid) => {
    if (e.target.checked && !permisosRol.find(p => p === uid)) {
      const nuevosPermisos = [...permisosRol, uid];
      setPermisosRol(nuevosPermisos);
    } else if (!e.target.checked) {
      const nuevosPermisos = permisosRol.filter(p => p !== uid);
      setPermisosRol(nuevosPermisos);
    }
  };

  useEffect(() => {
    cargarPermisos();
  }, []);

  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <h1 className="red">Editar Rol</h1>
        <FormProvider {...metodos}>
          <form
            onSubmit={e => e.preventDefault()}
            noValidate
            className="flex_col_container center"
            style={{width: '300px'}}>
            <Input
              type={'text'}
              id={'nombre_rol'}
              name={'nombre_rol'}
              placeholder={'Nombre de Rol'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
            />

            <select {...metodos.register('estado')} className="round">
              <option value="">Seleccione ...</option>
              <option value="A">Activo</option>
              <option value="I">Inactivo</option>
            </select>

            <h2>Permisos</h2>
            {errorPermisos && (
              <div style={{color: 'red', fontWeight: 'bold'}}>
                Debe seleccionar por lo menos un permiso
              </div>
            )}
            <div className="rounded-box" style={{height: '300px'}}>
              <div className="list">
                {permisos.map((perm, indice) => {
                  let className = 'list-option';
                  if (indice === 0) {
                    className = 'list-option-first';
                  } else if (indice === permisos.length - 1) {
                    className = 'list-option-last';
                  } else {
                    className = 'list-option';
                  }
                  let checked = false;
                  if (permisosRol.findIndex(p => p === perm.uid) >= 0) {
                    checked = true;
                  }
                  return (
                    <div key={`perm-${indice}`} className={className}>
                      <Form.Check
                        type={'checkbox'}
                        label={perm.nombre}
                        id={perm.uid}
                        onChange={e => cambioCheck(e, perm.uid)}
                        defaultChecked={checked}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div onClick={onSubmit} className="boton_rojo" role="button">
              Actualizar Rol
            </div>
          </form>
        </FormProvider>
      </Container>
    </div>
  );
};

export default EditRol;
