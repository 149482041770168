import React, {useEffect, useState} from 'react';
import {Container, Image} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {firestore} from '../firebaseconfig';
import {collection, getDocs, setDoc, doc, Timestamp} from 'firebase/firestore';
import {useForm, FormProvider} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {mostrar_mensaje} from '../redux/messageSlice';
import {setSolicitud} from '../redux/editSlice';
import Input from '../components/Input/input';

const EditSolicitud = () => {
  const {sol_editar} = useSelector(state => state.edicion);
  const [marcas, setMarcas] = useState([]);
  const [marcaLogo, setMarcaLogo] = useState('');
  const [errorMarca, setErrorMarca] = useState(false);
  const [errorEstado, setErrorEstado] = useState(false);
  const metodos = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cargarMarcas = async () => {
    const marcaArray = [];
    const querySnapshot = await getDocs(collection(firestore, 'marcas'));
    querySnapshot.forEach(doc => {
      const data = doc.data();
      marcaArray.push({uid: doc.id, ...data});
    });
    setMarcas(marcaArray);
    setMarcaLogo(marcaArray[0].url_logo);
  };

  const onChangeMarca = async e => {
    const marca = marcas.find(m => m.uid === e.target.value);
    if (marca) {
      setMarcaLogo(marca.url_logo);
    }
    setErrorMarca(false);
  };

  useEffect(() => {
    cargarMarcas();
  }, []);

  useEffect(() => {
    if (sol_editar) {
      const [fecha_cierre, hora_cierre] =
        sol_editar.fecha_cierre !== null
          ? sol_editar.fecha_cierre.split('T')
          : [null, null];
      const [fecha_apertura, hora_apertura] =
        sol_editar.fecha_apertura.split('T');
      metodos.reset({
        ...sol_editar,
        fecha_apertura: fecha_apertura,
        fecha_cierre: fecha_cierre,
        hora_apertura: new Date(sol_editar.fecha_apertura).toLocaleTimeString(
          'es-HN',
        ),
        hora_cierre:
          hora_cierre !== null
            ? new Date(sol_editar.fecha_cierre).toLocaleTimeString('es-HN')
            : null,
      });
    }
  }, [sol_editar, metodos]);

  const onSubmit = metodos.handleSubmit(async datos => {
    try {
      if (datos.estado === '') {
        setErrorEstado(true);
        return;
      }
      if (datos.marca === '') {
        setErrorMarca(true);
        return;
      }

      if (sol_editar.estado !== datos.estado) {
        let fecha_cierre = null;
        if (datos.estado !== 'A') {
          fecha_cierre = Timestamp.fromDate(new Date());
        }
        await setDoc(
          doc(firestore, `/solicitud/${sol_editar.uid}`),
          {
            anio: datos.anio,
            descripcion: datos.descripcion,
            estado: datos.estado,
            id_marca: datos.id_marca,
            modelo: datos.modelo,
            pieza: datos.pieza,
            fecha_cierre: fecha_cierre,
          },
          {merge: true},
        );
      } else {
        await setDoc(
          doc(firestore, `/solicitud/${sol_editar.uid}`),
          {
            anio: datos.anio,
            descripcion: datos.descripcion,
            estado: datos.estado,
            id_marca: datos.id_marca,
            modelo: datos.modelo,
            pieza: datos.pieza,
          },
          {merge: true},
        );
      }

      dispatch(mostrar_mensaje('Solicitud actualizada con exito!'));
      dispatch(setSolicitud(null));
      navigate('/solicitudes');
    } catch (error) {
      console.error(error);
      dispatch(mostrar_mensaje(JSON.stringify(error)));
    }
  });

  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <h1 className="red">Editar Solicitud</h1>
        <FormProvider {...metodos}>
          <form
            onSubmit={e => e.preventDefault()}
            noValidate
            className="flex_col_container center"
            style={{width: '400px'}}>
            <Input
              type={'text'}
              id={'usuario_nombre'}
              name={'usuario_nombre'}
              placeholder={'Nombre de usuario'}
              readOnly={true}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
            />
            {errorMarca && (
              <div style={{color: 'red', fontWeight: 'bold'}}>
                Debe seleccionar una marca.
              </div>
            )}
            <label htmlFor="id_marca">Marca</label>
            <div style={{display: 'flex', width: '100%', flexDirection: 'row'}}>
              <select
                id={'id_marca'}
                style={{flex: 2}}
                {...metodos.register('id_marca')}
                className="round"
                onChange={onChangeMarca}>
                {marcas.map((marca, indice) => {
                  return (
                    <option key={`marca-${indice}`} value={marca.uid}>
                      {marca.nombre}
                    </option>
                  );
                })}
              </select>
              {marcaLogo !== '' && (
                <Image
                  src={marcaLogo}
                  style={{width: '30px', height: '30px'}}
                />
              )}
            </div>
            <Input
              type={'text'}
              id={'anio'}
              name={'anio'}
              placeholder={'Año'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
                pattern: {
                  value: /^\d{4}$/g,
                  message: 'Formato de año invalido.',
                },
              }}
            />

            <Input
              type={'text'}
              id={'modelo'}
              name={'modelo'}
              placeholder={'Modelo'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
            />

            <Input
              type={'text'}
              id={'pieza'}
              name={'pieza'}
              placeholder={'Pieza'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
            />

            <Input
              type={'text'}
              id={'descripcion'}
              name={'descripcion'}
              placeholder={'Descripción'}
              validationOptions={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
            />
            {errorEstado && (
              <div style={{color: 'red', fontWeight: 'bold'}}>
                Debe seleccionar un estado
              </div>
            )}
            <label htmlFor="estado">Estado</label>
            <select
              {...metodos.register('estado')}
              className="round"
              id={'estado'}
              onChange={() => setErrorEstado(false)}>
              <option value="">Seleccione Estado</option>
              <option value="A">Activo</option>
              <option value="I">Inactivo</option>
            </select>

            <Input
              type={'date'}
              id={'fecha_apertura'}
              name={'fecha_apertura'}
              placeholder={'Fecha de Apertura'}
              readOnly={true}
              validationOptions={{
                valueAsDate: true,
              }}
            />

            <Input
              type={'time'}
              id={'hora_apertura'}
              name={'hora_apertura'}
              placeholder={'Hora de Apertura'}
              readOnly={true}
              validationOptions={{
                valueAsDate: true,
              }}
            />

            <Input
              type={'date'}
              id={'fecha_cierre'}
              name={'fecha_cierre'}
              placeholder={'Fecha de Cierre'}
              readOnly={true}
              validationOptions={{
                valueAsDate: true,
              }}
            />

            <Input
              type={'time'}
              id={'hora_cierre'}
              name={'hora_cierre'}
              placeholder={'Hora de Cierre'}
              readOnly={true}
              validationOptions={{
                valueAsDate: true,
              }}
            />

            <div onClick={onSubmit} className="boton_rojo" role="button">
              Actualizar Solicitud
            </div>
          </form>
        </FormProvider>
      </Container>
    </div>
  );
};

export default EditSolicitud;
