import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {getDocs, collection, doc} from 'firebase/firestore';
import {firestore} from '../firebaseconfig';
import {useNavigate} from 'react-router';
import {setPermiso} from '../redux/editSlice';
import {useDispatch} from 'react-redux';
import IconoLapiz from '../components/iconos/icono_lapiz';

const Permisos = () => {
  const [permisos, setPermisos] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cargarPermisos = async () => {
    const querySnapShot = await getDocs(collection(firestore, 'permisos'));
    const tmpPerm = [];
    querySnapShot.forEach(perm => {
      tmpPerm.push({uid: perm.id, ...perm.data()});
    });
    setPermisos(tmpPerm);
  };

  useEffect(() => {
    cargarPermisos();
  }, []);

  const handleEditarPermiso = async perm => {
    dispatch(setPermiso(perm));
    navigate(`/permisos/edit`);
  };
  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <h1 className="red">Permisos</h1>
        <table className="redTable">
          <thead>
            <tr>
              <th>Permiso</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {permisos.map((perm, index) => {
              return (
                <tr key={`perm-${index}`}>
                  <td>{perm.nombre}</td>
                  <td>{perm.estado === 'A' ? 'Activo' : 'Inactivo'}</td>
                  <td>
                    <span
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Editar Permiso"
                      data-tooltip-place="top">
                      <IconoLapiz
                        fillColor="#8e33ff"
                        onClick={() => handleEditarPermiso(perm)}
                      />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Container>
    </div>
  );
};

export default Permisos;
