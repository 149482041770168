import React, {useEffect, useState, useContext} from 'react';
import {Container} from 'react-bootstrap';
import {AuthContext} from '../AuthProvider';
import {firestore} from '../firebaseconfig';
import {collection, getDocs} from 'firebase/firestore';
import {useNavigate} from 'react-router-dom';
import IconoLapiz from '../components/iconos/icono_lapiz';
import {useDispatch} from 'react-redux';
import axios from 'axios';
import {setOferta} from '../redux/editSlice';

const Ofertas = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [ofertas, setOfertas] = useState([]);
  const {getToken, user} = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getUsuarios = async () => {
    if (!user) return;
    const url = 'https://getusuarios-i52rdlruva-uc.a.run.app';
    const token = await getToken();
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      const data = res.data;
      setUsuarios(data);
    }
  };

  useEffect(() => {
    getUsuarios();
  }, [user]);

  const getOfertas = async () => {
    if (!user || !usuarios || usuarios.length === 0) return;
    const oferArray = [];
    const querySnapshot = await getDocs(collection(firestore, 'ofertas'));
    querySnapshot.forEach(doc => {
      const data = doc.data();
      const usuario = usuarios.find(usr => usr.uid === data.id_yonker);
      oferArray.push({
        ...data,
        usuario_nombre: usuario?.displayName,
        uid: doc.id,
      });
    });
    setOfertas(oferArray);
  };

  useEffect(() => {
    getOfertas();
  }, [usuarios]);

  const handleEditOferta = ofr => {
    dispatch(
      setOferta({
        ...ofr,
        fecha_oferta: ofr.fecha_oferta.toDate().toISOString(),
        fecha_cierre:
          ofr.fecha_cierre !== null
            ? ofr.fecha_cierre.toDate().toISOString()
            : null,
      }),
    );
    navigate(`/ofertas/edit`);
  };

  return (
    <div className="fondo_autos">
      <Container className="contenedor">
        <h1 className="red">Ofertas</h1>
        <table className="redTable">
          <thead>
            <tr>
              <th>Usuario</th>
              <th>Descripción</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {ofertas.map((ofr, indice) => {
              return (
                <tr key={`ofr-${indice}`}>
                  <td>{ofr.usuario_nombre}</td>
                  <td>{ofr.descripcion}</td>
                  <td>{ofr.estado === 'A' ? 'Activo' : 'Inactivo'}</td>
                  <td>
                    <span
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Editar Oferta"
                      data-tooltip-place="top">
                      <IconoLapiz
                        fillColor="#8e33ff"
                        onClick={() => handleEditOferta(ofr)}
                      />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Container>
    </div>
  );
};

export default Ofertas;
