import EditEvaluacion from './views/EditEvaluacion';
import EditOferta from './views/EditOferta';
import EditPermiso from './views/EditPermiso';
import EditRol from './views/EditRol';
import EditSolicitud from './views/EditSolicitud';
import EditUser from './views/EditUser';
import Evaluaciones from './views/Evaluaciones';
import Inicio from './views/Inicio';
import Login from './views/Login';
import NewRol from './views/NewRol';
import NewUser from './views/NewUser';
import NuevoPass from './views/NuevoPass';
import Ofertas from './views/Ofertas';
import Permisos from './views/Permisos';
import Roles from './views/Roles';
import Solicitudes from './views/Solicitudes';
import Usuarios from './views/Usuarios';
import PrivateRoute from './PrivateRoute';
import PoliticaPriv from './views/PoliticaPriv';
import DeleteAccount from './views/DeleteAccount';
import Marcas from './views/Marcas';
import NewMarca from './views/NewMarca';
import EditMarca from './views/EditMarca';

const routes = [
  {path: '/', element: <Login />},
  {path: '/politica_priv', element: <PoliticaPriv />},
  {path: '/account_delete', element: <DeleteAccount />},
  {
    path: '/inicio',
    element: (
      <PrivateRoute>
        <Inicio />
      </PrivateRoute>
    ),
  },
  {
    path: '/roles',
    element: (
      <PrivateRoute>
        <Roles />
      </PrivateRoute>
    ),
  },
  {
    path: '/roles/new',
    element: (
      <PrivateRoute>
        <NewRol />
      </PrivateRoute>
    ),
  },
  {
    path: '/roles/edit/:id',
    element: (
      <PrivateRoute>
        <EditRol />
      </PrivateRoute>
    ),
  },
  {
    path: '/usuarios',
    element: (
      <PrivateRoute>
        <Usuarios />
      </PrivateRoute>
    ),
  },
  {
    path: '/usuarios/new',
    element: (
      <PrivateRoute>
        <NewUser />
      </PrivateRoute>
    ),
  },
  {
    path: '/usuarios/edit/:id',
    element: (
      <PrivateRoute>
        <EditUser />
      </PrivateRoute>
    ),
  },
  {
    path: '/usuarios/changepass/:id',
    element: (
      <PrivateRoute>
        <NuevoPass />
      </PrivateRoute>
    ),
  },
  {
    path: '/solicitudes',
    element: (
      <PrivateRoute>
        <Solicitudes />
      </PrivateRoute>
    ),
  },
  {
    path: '/solicitudes/edit/:id',
    element: (
      <PrivateRoute>
        <EditSolicitud />
      </PrivateRoute>
    ),
  },
  {
    path: '/permisos',
    element: (
      <PrivateRoute>
        <Permisos />
      </PrivateRoute>
    ),
  },
  {
    path: '/permisos/edit',
    element: (
      <PrivateRoute>
        <EditPermiso />
      </PrivateRoute>
    ),
  },
  {
    path: '/ofertas',
    element: (
      <PrivateRoute>
        <Ofertas />
      </PrivateRoute>
    ),
  },
  {
    path: '/ofertas/edit',
    element: (
      <PrivateRoute>
        <EditOferta />
      </PrivateRoute>
    ),
  },
  {
    path: '/evaluaciones',
    element: (
      <PrivateRoute>
        <Evaluaciones />
      </PrivateRoute>
    ),
  },
  {
    path: '/evaluaciones/edit',
    element: (
      <PrivateRoute>
        <EditEvaluacion />
      </PrivateRoute>
    ),
  },
  {
    path: '/marcas',
    element: (
      <PrivateRoute>
        <Marcas />
      </PrivateRoute>
    ),
  },
  {
    path: '/marcas/new',
    element: (
      <PrivateRoute>
        <NewMarca />
      </PrivateRoute>
    ),
  },
  {
    path: '/marcas/edit',
    element: (
      <PrivateRoute>
        <EditMarca />
      </PrivateRoute>
    ),
  },
];

export default routes;
