import React, { useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Container, Image } from "react-bootstrap";
import Input from "../components/Input/input";
import IconoSobre from "../components/iconos/iconoSobre";
import IconoCandado from "../components/iconos/iconoCandado";
import Logo from '../imagenes/logo_transparente.png';
import { AuthContext } from "../AuthProvider";
import { useNavigate } from "react-router-dom";
const Login = () => {

    const { loginUser, loading, user } = useContext(AuthContext);
    const navigate = useNavigate();
    const metodos = useForm();

    const onSubmit = metodos.handleSubmit(datos => {
        console.log(datos);
        const { email, password } = datos;
        loginUser(email, password)
            .then((result) => {
                console.log(result);
                navigate("/inicio");
            })
            .catch((error) => console.log(error.message));

    });

    if (user) {
        navigate('/inicio');
    }

    return (
        <div className="fondo_autos">
            {!loading &&
                <Container className="contenedor">
                    <Image className="logo_login" src={Logo} />
                    <FormProvider {...metodos}>
                        <form
                            onSubmit={e => e.preventDefault()}
                            noValidate
                            className="flex_col_container center"
                        >
                            <Input id={'email'}
                                name={'email'}
                                type={'text'}
                                placeholder={'Correo Electrónico'}
                                icon={<IconoSobre width={'30px'} height={'35px'} />}
                                width={'400px'}
                                validationOptions={{
                                    required: {
                                        value: true,
                                        message: 'Campo requerido'
                                    }
                                }}
                            />
                            <Input id={'password'}
                                name={'password'}
                                type={'password'}
                                placeholder={'Contraseña'}
                                icon={<IconoCandado width={'35px'} height={'35px'} />}
                                width={'400px'}
                                validationOptions={{
                                    required: {
                                        value: true,
                                        message: 'Campo requerido'
                                    }
                                }}
                            />
                            <div onClick={onSubmit} className="boton_rojo" role="button">
                                Iniciar Sesión
                            </div>
                        </form>
                    </FormProvider>
                </Container>
            }
        </div>
    )
}

export default Login;